import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";

const Page = forwardRef(({ children, title = "", ...rest }, ref) => (
  <div className="xs:p-2 sm:p-3 md:p-4 lg:p-6 min-h-screen" ref={ref} {...rest}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </div>
));
Page.displayName = "Page";

export default Page;
