import Head from "next/head";
import { useRouter } from "next/router";
import Link from "next/link";
import Button from "../components/v2/Button";
import Page from "../components/v2/Rooter";
import { useEffect } from "react";

const Error = () => {
  const router = useRouter();
  const hostname = router?.asPath;

  useEffect(() => {
    if (hostname?.includes("applications")) {
      router.push(process.env.NEXT_PUBLIC_USER_QUOTES);
    }
  }, []);

  return (
    <Page
      title={
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "Trucking Insurance Provider | LuckyTruck"
          : "Trucking Insurance Provider | Flexport"
      }
    >
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
          />
          <meta
            name="title"
            content="We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now."
          />
          <meta
            name="keywords"
            content="trucking Insurance, truck insurance providers"
          />
          <meta
            name="description"
            content="Looking for a trucking insurance that won't break the bank? LuckyTruck has you covered! We offer affordable rates and comprehensive coverage, so you can rest assured that your truck is protected. Give us a call today to get started!"
          />
        </Head>
        <div
          className="section-5"
          style={{ height: "100vh", padding: "2rem 0" }}
        >
          <div
            className="navImage"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link href={`/`} prefetch={false}>
              {process.env.NEXT_PUBLIC_THEME === "LT" ? (
                <img
                  src={"/Images/logo-circle.webp"}
                  alt="logo-circle"
                  lang="eng"
                  className="w-16 h-16"
                />
              ) : (
                <img
                  src={"/Images/flexport/Logo-Flexport.svg"}
                  alt="flexport"
                  lang="eng"
                  className="w-40"
                />
              )}
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20vh",
            }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt="404"
                  lang="eng"
                  src={"/Images/404.svg"}
                  style={{ width: "33vw" }}
                />
              </div>
              <h1
                className="p-mb-5 p-mt-2"
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "4.5vw",
                  lineHeight: "5vw",
                }}
              >
                Page not found
              </h1>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  title="BACK TO HOMEPAGE"
                  onButtonClick={() => router.replace("/")}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Page>
  );
};
Error.layout = "L404";
export default Error;
